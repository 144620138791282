'use client';

import Logo from '@/public/logos/logo.svg';
import Picture from '@/public/sign/owner/illustration.svg';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

export default function SignLayout({
	children,
	sign,
}: {
	children: React.ReactNode;
	sign: 'up' | 'up-form' | 'in';
}) {
	return (
		<main
			className={clsx(
				'relative h-screen w-screen items-center justify-center lg:flex lg:bg-white',
				{
					'bg-grey-white': sign === 'up',
					'bg-white': sign === 'in' || sign === 'up-form',
				},
			)}
		>
			<div className="mx-auto w-full max-w-[450px] px-2 pt-6 lg:flex-1 lg:px-0 lg:pt-0">
				<header className="top-[50px] mb-6 flex w-full max-w-[450px] items-center justify-center lg:absolute lg:mb-0">
					<Link href="/" aria-label="Lien pour retourner à la page d'accueil">
						<Image src={Logo} alt="" role="presentation" width={162} priority className="mx-auto" />
					</Link>
				</header>

				{children}
			</div>

			<div className="lg:relative lg:flex lg:h-screen">
				<div className="w-full px-3 pt-16 lg:bottom-6 lg:right-6 lg:top-6 lg:my-6 lg:mr-6 lg:flex lg:max-w-[520px] lg:flex-col lg:justify-center lg:rounded-[32px] lg:border lg:border-grey-light lg:bg-grey-white lg:px-8 lg:py-[6.625rem]">
					<Image
						className="mx-auto"
						src={Picture}
						alt=""
						role="presentation"
						priority
						width={160}
						height={179}
						quality={100}
					/>

					<h2 className="mt-8 text-center text-[2.125rem] font-bold leading-10">
						Facilitez votre gestion locative
					</h2>
					{(sign === 'up' || sign === 'up-form') && (
						<p className="mx-auto mb-8 mt-4 max-w-[361px] text-center text-base font-medium text-grey-darker">
							Créez votre compte en seulement 2 minutes, et facilitez votre gestion locative&nbsp;!
						</p>
					)}
					{sign === 'in' && (
						<p className="mx-auto mb-8 mt-4 max-w-[361px] text-center text-base font-medium text-grey-darker">
							Connectez-vous à votre compte, et facilitez votre gestion locative&nbsp;!
						</p>
					)}
				</div>
			</div>
		</main>
	);
}
